import { render, staticRenderFns } from "./stripe.vue?vue&type=template&id=3016aa16&"
import script from "./stripe.vue?vue&type=script&lang=js&"
export * from "./stripe.vue?vue&type=script&lang=js&"
import style0 from "./stripe.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/salabam.com/cancellara-src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3016aa16')) {
      api.createRecord('3016aa16', component.options)
    } else {
      api.reload('3016aa16', component.options)
    }
    module.hot.accept("./stripe.vue?vue&type=template&id=3016aa16&", function () {
      api.rerender('3016aa16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/blocks/stripe.vue"
export default component.exports